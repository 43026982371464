import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardHeader
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";

import "./contentEditor.scss";
import { GLOBAL_QUERY, GLOBAL_QUERY_VARIABLES } from "./index";

const Basic = (props: {
    initialContent: string;
    save: (content: string) => void;
}) => {
    const ref: any = useRef(null);

    useEffect(() => {
        const save = () => {
            const c = ref.current.getEditor().getContents();
            props.save(JSON.stringify(c));
        };

        ref.current.getEditor().keyboard.addBinding({
            key: "S",
            shortKey: true,
            handler: function() {
                save();
            }
        });
    }, [props]);

    return (
        <div className="quill-wrapper">
            <ReactQuill ref={ref} defaultValue={props.initialContent} />
        </div>
    );
};

const editorComponentMap: { [key: string]: any } = {
    basic: Basic
};

export default (props: { website: any; pageID: string }) => {
    const [sizing, setSizing] = useState("desktop");

    const page = props.website.pages.find(
        (page: any) => page.id === props.pageID
    );

    const saveContentMutation = useMutation(
        gql`
            mutation CreateWebsitePageContent(
                $input: CreateWebsitePageContentInput!
            ) {
                createWebsitePageContent(input: $input) {
                    id
                    content
                }
            }
        `,
        {
            update(proxy, { data }: any) {
                const content: any = proxy.readQuery({
                    query: GLOBAL_QUERY,
                    variables: GLOBAL_QUERY_VARIABLES
                });

                const page = content.website.pages.find(
                    (page: any) => page.id === props.pageID
                );

                if (!page) {
                    return;
                }

                page.content = data.createWebsitePageContent.content;

                proxy.writeQuery({
                    query: GLOBAL_QUERY,
                    data: content,
                    variables: GLOBAL_QUERY_VARIABLES
                });
            }
        }
    );

    const saveContent = (content: string) => {
        saveContentMutation({
            variables: {
                input: {
                    content,
                    pageID: page.id
                }
            }
        }).then(r => console.log(r));
    };

    if (!page) {
        return null;
    }

    const Component =
        editorComponentMap[page.type.slug] || editorComponentMap.basic;

    return (
        <Card className="editor-card page-editor-card">
            <CardHeader>
                <div>
                    <span>{page.name}</span>
                    <span>{" - "}</span>
                    <span>{page.path}</span>
                </div>
                <div>
                    <ButtonToolbar>
                        <ButtonGroup style={{ marginRight: "0.5rem" }}>
                            <Button size="sm" color="success">
                                Save
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button
                                size="sm"
                                active={sizing === "mobile"}
                                onClick={() => setSizing("mobile")}>
                                Mobile
                            </Button>
                            <Button
                                size="sm"
                                active={sizing === "desktop"}
                                onClick={() => setSizing("desktop")}>
                                Desktop
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
            </CardHeader>
            <CardBody>
                <Component
                    website={props.website}
                    save={saveContent}
                    initialContent={JSON.parse(page.content)}
                />
            </CardBody>
        </Card>
    );
};
