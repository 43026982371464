import React from "react";
import "./billingPlan.scss";

export default () => {
    return (
        <div className="billingPlan">
            <div>
                <h4>Plan One</h4>
            </div>
            <div>
                <h4>Plan Two</h4>
            </div>
            <div>
                <h4>Plan Three</h4>
            </div>
        </div>
    );
};
