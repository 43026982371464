import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import BillingPlan from "./billingPlan";
import BillingHistory from "./billingHistory";

export default (props: { account: any }) => {
    if (!props.account) {
        return null;
    }

    return (
        <Card className="page-card">
            <CardHeader>Billing</CardHeader>
            <CardBody style={{ margin: "1rem" }}>
                <Row>
                    <Col md={4}>
                        <h5>Plan</h5>
                    </Col>
                    <Col>
                        <BillingPlan />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={4}>
                        <h5>Billing Details</h5>
                    </Col>
                    <Col />
                </Row>
                <hr />
                <Row>
                    <Col md={4}>
                        <h5>Invoices</h5>
                    </Col>
                    <Col>
                        <BillingHistory />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
