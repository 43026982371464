import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloProvider } from "react-apollo-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";

import Router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const uri = window.location.host.startsWith("localhost")
    ? "http://localhost:4000"
    : "https://skeo8p3u4l.execute-api.ap-southeast-2.amazonaws.com/prod/graphql";

const httpLink = createHttpLink({
    uri
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("lendousAuthToken");

    console.log("token", token);

    return {
        headers: {
            ...headers,
            authorization:
                token && token !== "undefined" ? `Bearer ${token}` : ""
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router />
    </ApolloProvider>,
    document.getElementById("root")
);
