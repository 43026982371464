import React from "react";
import useLocation from "react-use/lib/useLocation";

import App from "./app";
import Marketing from "./marketing";
import HomePage from "./marketing/homePage";
import Login from "./auth/login";

import Restaurant from "./marketing/pages/industry/restaurant";
import Health from "./marketing/pages/industry/health";
import Dentistry from "./marketing/pages/industry/dentistry";
import RealEstate from "./marketing/pages/industry/realEstate";
import Financial from "./marketing/pages/industry/financial";

import Website from "./marketing/pages/product/website";
import Reputation from "./marketing/pages/product/reputation";
import SocialMedia from "./marketing/pages/product/socialMedia";
import Competition from "./marketing/pages/product/competition";
import Mobile from "./marketing/pages/product/mobile";

import AboutUs from "./marketing/pages/company/aboutus";
import Pricing from "./marketing/pages/company/pricing";
import Contact from "./marketing/pages/company/contact";
import Privacy from "./marketing/pages/company/privacy";
import Security from "./marketing/pages/company/security";

const marketingPages: { [key: string]: any } = {
    "/": <HomePage />,
    "/login": <Login />,
    "/product/website": <Website />,
    "/product/reputation": <Reputation />,
    "/product/socialMedia": <SocialMedia />,
    "/product/competition": <Competition />,
    "/product/mobile": <Mobile />,
    "/industry/restaurant": <Restaurant />,
    "/industry/health": <Health />,
    "/industry/dentistry": <Dentistry />,
    "/industry/realEstate": <RealEstate />,
    "/industry/financial": <Financial />,
    "/company/about": <AboutUs />,
    "/company/pricing": <Pricing />,
    "/company/contact": <Contact />,
    "/company/privacy": <Privacy />,
    "/company/security": <Security />
};

export default () => {
    const location: any = useLocation();

    if (location.pathname.startsWith("/app")) {
        return <App />;
    } else if (marketingPages[location.pathname]) {
        return <Marketing>{marketingPages[location.pathname]}</Marketing>;
    } else {
        return <Marketing />;
    }
};
