import React, { useState, useRef } from "react";
import { Alert, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";

import "./login.scss";

export default () => {
    const login = useMutation(gql`
        mutation Login($input: LoginInput!) {
            login(input: $input) {
                token
                error
            }
        }
    `);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const emailRef = useRef<any>(null);
    const passwordRef = useRef<any>(null);

    const submit = (event: any) => {
        event.preventDefault();

        setLoading(true);

        login({
            variables: {
                input: {
                    email,
                    password
                }
            }
        }).then((r: any) => {
            setLoading(false);

            if (r.data.login.error) {
                setError(r.data.login.error);
            } else {
                localStorage.setItem("lendousAuthToken", r.data.login.token);

                window.history.pushState({}, window.document.title, "/app");
            }
        });
    };

    return (
        <div className="login-container">
            <div className="login-container-box">
                <h1>Login</h1>

                {error === "InvalidPassword" && (
                    <Alert color="danger">Invalid password!!!!</Alert>
                )}

                {error === "MissingUser" && (
                    <Alert color="danger">Can't find that user :(((((</Alert>
                )}

                <Form onSubmit={submit}>
                    <FormGroup>
                        <Label for="form-login">Email</Label>
                        <Input
                            ref={emailRef}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="text"
                            name="slug"
                            id="form-login"
                            placeholder="Email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="form-password">Password</Label>
                        <Input
                            ref={passwordRef}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            name="slug"
                            id="form-password"
                            placeholder="Password"
                        />
                    </FormGroup>
                    <Button disabled={loading} color="success">
                        Login
                    </Button>
                </Form>
            </div>
            <div className="footer">
                <b>Forgot your password?</b>
            </div>
        </div>
    );
};
