import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import useLocation from "react-use/lib/useLocation";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

import Content from "./content";
import Design from "./design";

import "./side-nav.scss";
import "./index.scss";

export const GLOBAL_QUERY = gql`
    query GlobalQuery($accountID: String) {
        website(accountID: $accountID) {
            id
            theme {
                id
                name
                slug
                options {
                    name
                    slug
                    type
                    default
                }
            }
            themeOptions {
                slug
                value
            }
            pages {
                id
                name
                publishStatus
                path
                type {
                    icon
                    slug
                }
                stats {
                    month
                }
                content
            }
        }
    }
`;

export const GLOBAL_QUERY_VARIABLES = {
    accountID: null
};

export default (props: { account: any }) => {
    GLOBAL_QUERY_VARIABLES.accountID = props.account.id;

    const { data, loading } = useQuery(GLOBAL_QUERY, {
        variables: GLOBAL_QUERY_VARIABLES
    });

    const location = useLocation();
    const selectedTab =
        (location.pathname && location.pathname.split("/")[3]) || "";

    const tabs = ["Overview", "Content", "Design"];

    const onClickTab = (tabName: string) => {
        const url = `/app/website/${tabName.toLowerCase()}`;

        window.history.pushState({}, window.document.title, url);
    };

    const tabComponentMap: { [key: string]: any } = {
        "": () => <h5>Overview</h5>,
        content: Content,
        design: Design
    };
    const TabComponent = tabComponentMap[selectedTab.toLowerCase()] || null;

    if (loading) {
        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div>
                <Nav vertical className="side-nav">
                    {tabs.map((tab, index) => (
                        <NavItem
                            key={tab}
                            className={
                                selectedTab === tab.toLowerCase() ||
                                (index === 0 && selectedTab === "")
                                    ? "active"
                                    : ""
                            }
                            onClick={() => onClickTab(index === 0 ? "" : tab)}>
                            <NavLink href="#">{tab}</NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            <div style={{ flex: 1 }}>
                <TabComponent website={data.website} />
            </div>
        </div>
    );
};
