import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import DeleteModal from "./contentListDeleteModal";
import "./contentList.scss";

export default (props: { website: any }) => {
    const [deletingPage, setDeletingPage] = useState(null);

    const pages: any[] = props.website.pages;

    const clickPage = (page: any) => {
        const url = `/app/website/content/${page.id}`;
        window.history.pushState({}, window.document.title, url);
    };

    const clickNewPage = () => {
        const url = `/app/website/content/new`;
        window.history.pushState({}, window.document.title, url);
    };

    const clickPageDelete = (event: any, page: any) => {
        event.preventDefault();
        event.stopPropagation();

        setDeletingPage(page);
    };

    return (
        <Card className="page-card">
            <CardHeader
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                <span>Pages</span>
                <button onClick={clickNewPage}>New Page</button>
            </CardHeader>
            <CardBody>
                <DeleteModal
                    toggle={() => setDeletingPage(null)}
                    page={deletingPage}
                />

                <div>
                    {pages.map((page, index) => {
                        return (
                            <div
                                key={index}
                                className="page-row"
                                onClick={() => clickPage(page)}>
                                <div className="page-type-icon">
                                    <i className="material-icons">
                                        {page.type.icon}
                                    </i>
                                </div>

                                <div className="page-flex">
                                    <div>{page.name}</div>
                                    <div className="page-publish-status">
                                        <div
                                            className={`page-publish-status-icon ${page.publishStatus.toLowerCase()}`}
                                        />
                                        <div>{page.publishStatus}</div>
                                    </div>
                                </div>

                                <div className="page-flex">
                                    <div>{page.url}</div>
                                    <div>url</div>
                                </div>

                                <div className="page-flex">
                                    <div>{page.stats.month} views</div>
                                    <div>last 30 days</div>
                                </div>

                                <div className="page-icons">
                                    <div className="page-icon muted">
                                        <i className="material-icons">
                                            history
                                        </i>
                                    </div>
                                    <div
                                        className="page-icon muted"
                                        onClick={e => clickPageDelete(e, page)}>
                                        <i className="material-icons">delete</i>
                                    </div>
                                    <div className="page-icon">
                                        <i className="material-icons">edit</i>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardBody>
        </Card>
    );
};
