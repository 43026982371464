import React from "react";
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import "./index.scss";

const switchToLogin = () => {
    window.history.pushState({}, window.document.title, "/login");
};

export default (props: { children?: any }) => {
    const defaultChildren = <button onClick={switchToLogin}>Login</button>;

    const clickTab = (event: any) => {
        event.preventDefault();

        const href = event.target.attributes.getNamedItem("href").nodeValue;
        console.log(href);
        window.history.pushState({}, window.document.title, href);
    };

    const industries: { [key: string]: string } = {
        restaurant: "Restaurants",
        health: "Health Services",
        dentistry: "Dentists",
        realEstate: "Real Estate",
        financial: "Financial Services"
    };

    const products: { [key: string]: string } = {
        website: "Website",
        reputation: "Reputation",
        socialMedia: "Social Media",
        competition: "Competitor Analysis",
        mobile: "Mobile Apps"
    };

    const companyPages: { [key: string]: string } = {
        about: "About us",
        pricing: "Pricing",
        contact: "Contact Us",
        privacy: "Privacy Policy",
        security: "Security"
    };

    const gotoIndustry = (industry: string) => {
        window.history.pushState(
            {},
            window.document.title,
            `/industry/${industry}`
        );
    };

    const gotoProduct = (product: string) => {
        window.history.pushState(
            {},
            window.document.title,
            `/product/${product}`
        );
    };

    const gotoCompanyPage = (companyPage: string) => {
        window.history.pushState(
            {},
            window.document.title,
            `/company/${companyPage}`
        );
    };

    const preventDefault = (e: any) => e.preventDefault();

    return (
        <div className="page-layout">
            <Navbar color="light" light expand="md">
                <NavbarBrand onClick={clickTab} href="/">
                    lendous
                </NavbarBrand>

                <Nav navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Product
                        </DropdownToggle>
                        <DropdownMenu className="product-dropdown">
                            <div
                                className="product-container product-website"
                                onClick={() => gotoProduct("website")}>
                                <div className="product-icon">
                                    <i className="material-icons">web</i>
                                </div>
                                <div className="product-details">
                                    <h5>Website</h5>
                                    <p>A sexy website for you.</p>
                                </div>
                            </div>
                            <div
                                className="product-container product-reputation"
                                onClick={() => gotoProduct("reputation")}>
                                <div className="product-icon">
                                    <i className="material-icons">
                                        verified_user
                                    </i>
                                </div>
                                <div className="product-details">
                                    <h5>Reputation</h5>
                                    <p>Manage how the world sees you.</p>
                                </div>
                            </div>
                            <div
                                className="product-container product-social"
                                onClick={() => gotoProduct("socialMedia")}>
                                <div className="product-icon">
                                    <i className="material-icons">share</i>
                                </div>
                                <div className="product-details">
                                    <h5>Social Media</h5>
                                    <p>How people find you.</p>
                                </div>
                            </div>
                            <div
                                className="product-container product-competition"
                                onClick={() => gotoProduct("competition")}>
                                <div className="product-icon">
                                    <i className="material-icons">people</i>
                                </div>
                                <div className="product-details">
                                    <h5>Competition</h5>
                                    <p>Gain the competitive edge.</p>
                                </div>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Industries
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>Restaurants</DropdownItem>
                            <DropdownItem>Medical Clinics</DropdownItem>
                            <DropdownItem>Dentists</DropdownItem>
                            <DropdownItem>Real Estate</DropdownItem>
                            <DropdownItem>Financial Services</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Resources
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>Case Studies</DropdownItem>
                            <DropdownItem>Reports</DropdownItem>
                            <DropdownItem>Blog</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>

                <Nav className="ml-auto" navbar>
                    <NavItem onClick={clickTab}>
                        <NavLink href="/login">Login</NavLink>
                    </NavItem>
                    <NavItem onClick={clickTab}>
                        <NavLink href="/signup">
                            <Button>Sign up</Button>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Navbar>

            <div className="page-layout-content">
                {props.children || defaultChildren}
            </div>

            <footer>
                <div className="contain">
                    <div>
                        <p>
                            <b>Product</b>
                        </p>
                        {Object.keys(products).map(product => (
                            <p
                                onClick={() => gotoProduct(product)}
                                key={product}>
                                <a
                                    href={`/product/${product}`}
                                    onClick={preventDefault}>
                                    {products[product]}
                                </a>
                            </p>
                        ))}
                    </div>
                    <div>
                        <p>
                            <b>Industries</b>
                        </p>
                        {Object.keys(industries).map(industry => (
                            <p
                                onClick={() => gotoIndustry(industry)}
                                key={industry}>
                                <a
                                    href={`/industry/${industry}`}
                                    onClick={preventDefault}>
                                    {industries[industry]}
                                </a>
                            </p>
                        ))}
                    </div>
                    <div />
                    <div>
                        <p>
                            <b>Company</b>
                        </p>
                        {Object.keys(companyPages).map(companyPage => (
                            <p
                                onClick={() => gotoCompanyPage(companyPage)}
                                key={companyPage}>
                                <a
                                    href={`/company/${companyPage}`}
                                    onClick={preventDefault}>
                                    {companyPages[companyPage]}
                                </a>
                            </p>
                        ))}
                    </div>
                </div>
                <div className="footer-end" />
            </footer>
        </div>
    );
};
