import React from "react";
import useLocation from "react-use/lib/useLocation";

import DesignOverview from "./designOverview";

export default (props: { website: any }) => {
    const location = useLocation();

    const isViewingThemes =
        location.pathname && location.pathname.split("/")[4];

    if (isViewingThemes) {
        return null;
    } else {
        return <DesignOverview website={props.website} />;
    }
};
