import React from "react";
import "./homePage.scss";

export default () => {
    return (
        <div>
            <div className="hero">
                <h1>Everything your</h1>
                <h1>
                    <span className="highlight">local business</span> needs
                </h1>
                <h1>to succeed online.</h1>

                <h3 className="break">
                    Stand out online. Attract new customers.
                </h3>
                <h3 className="highlight">Become the obvious choice.</h3>
            </div>

            <div className="feature-platter">
                <div className="feature-website">
                    <h4>Website Management</h4>
                </div>
                <div className="feature-reputation">
                    <h4>Reputation</h4>
                </div>
                <div className="feature-social">
                    <h4>Social Media</h4>
                </div>
                <div className="feature-competitor">
                    <h4>Competitive Analysis</h4>
                </div>
            </div>

            <div className="block">We understand your industry!</div>
            <div className="block">
                Quote about online reputation with stock background.
            </div>
            <div className="block">Review cycles.</div>
            <div className="block">Call to action.</div>
            <div className="block">Stats about online reputation.</div>
            <div className="block">Sassy ending.</div>
        </div>
    );
};
