import React from "react";
import useLocation from "react-use/lib/useLocation";

import ContentList from "./contentList";
import ContentEditor from "./contentEditor";
import ContentNew from "./contentNew";

export default (props: { website: any }) => {
    const location = useLocation();

    const pageID = location.pathname && location.pathname.split("/")[4];

    if (pageID && pageID.toLowerCase() === "new") {
        return <ContentNew website={props.website} />;
    } else if (pageID) {
        return <ContentEditor website={props.website} pageID={pageID} />;
    } else {
        return <ContentList website={props.website} />;
    }
};
