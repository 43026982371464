import React, { useState, useEffect } from "react";
import useLocation from "react-use/lib/useLocation";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import Website from "./website";
import Settings from "./settings";
import Account from "./account";

export default () => {
    const location = useLocation();
    const selectedTab =
        (location.pathname && location.pathname.split("/")[2]) || "";

    const onClickTab = (tabName: string) => {
        const url = `/app/${tabName.toLowerCase()}`;

        window.history.pushState({}, window.document.title, url);
    };

    const onClickBrand = (event: any) => {
        event.preventDefault();
        window.history.pushState({}, window.document.title, `/app`);
    };

    const [selectedAccount, setSelectedAccount] = useState(0);

    const { loading, data } = useQuery(gql`
        query {
            user {
                name
                email
                accounts {
                    id
                    name
                    products
                }
            }
        }
    `);

    const logout = () => {
        localStorage.removeItem("lendousAuthToken");

        window.history.pushState({}, window.document.title, "/");
    };

    const options = () => {
        window.history.pushState({}, window.document.title, "/app/settings");
    };

    const accountSettings = () => {
        window.history.pushState({}, window.document.title, "/app/account");
    };

    console.log(data.user);

    const productComponentMap: { [key: string]: any } = {
        website: (
            <Website
                account={
                    loading === false &&
                    data.user &&
                    data.user.accounts[selectedAccount]
                }
            />
        ),
        settings: <Settings />,
        account: (
            <Account
                account={
                    loading === false &&
                    data.user &&
                    data.user.accounts[selectedAccount]
                }
            />
        )
    };

    const productContent =
        productComponentMap[selectedTab.toLowerCase()] || null;

    useEffect(() => {
        if (!localStorage.lendousAuthToken) {
            window.history.pushState({}, window.document.title, "/login");
        }
    }, []);

    if (!localStorage.lendousAuthToken) {
        return null;
    }

    return (
        <div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="#" onClick={onClickBrand}>
                    lendous
                </NavbarBrand>
                <Nav navbar>
                    {loading === false &&
                        data.user &&
                        data.user.accounts[selectedAccount].products.map(
                            (product: any) => (
                                <NavItem key={product}>
                                    <NavLink
                                        href="#"
                                        onClick={() => onClickTab(product)}
                                        className={
                                            selectedTab ===
                                            product.toLowerCase()
                                                ? "active"
                                                : ""
                                        }>
                                        {product}
                                    </NavLink>
                                </NavItem>
                            )
                        )}
                </Nav>
                <Nav className="ml-auto" navbar>
                    {loading === false && data.user && (
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                {data.user.accounts[selectedAccount].name}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={accountSettings}>
                                    Account Settings
                                </DropdownItem>
                                <DropdownItem divider />
                                {data.user.accounts.map(
                                    (account: any, index: number) => {
                                        return (
                                            <DropdownItem
                                                key={account.id}
                                                className={
                                                    index === selectedAccount
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    setSelectedAccount(index)
                                                }>
                                                {account.name}
                                            </DropdownItem>
                                        );
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            David Cook
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={options}>
                                User Settings
                            </DropdownItem>
                            <DropdownItem onClick={logout}>Logout</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>

            {loading === false && productContent}
        </div>
    );
};
