import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useMutation } from "react-apollo-hooks";
import { GLOBAL_QUERY, GLOBAL_QUERY_VARIABLES } from "./index";
import gql from "graphql-tag";

export default (props: { toggle: () => void; page: any }) => {
    const deletePage = useMutation(
        gql`
            mutation deleteWebsitePage($pageID: String!) {
                deleteWebsitePage(pageID: $pageID) {
                    id
                }
            }
        `,
        {
            variables: {
                pageID: props.page && props.page.id
            },
            update(proxy, { data: { deleteWebsitePage } }: any) {
                const data: any = proxy.readQuery({
                    query: GLOBAL_QUERY,
                    variables: GLOBAL_QUERY_VARIABLES
                });

                data.website.pages = data.website.pages.filter(
                    (page: any) => page.id !== deleteWebsitePage.id
                );

                proxy.writeQuery({
                    query: GLOBAL_QUERY,
                    data,
                    variables: GLOBAL_QUERY_VARIABLES
                });
            }
        }
    );

    const clickConfirm = () => {
        deletePage();
        props.toggle();
    };

    return (
        <Modal isOpen={props.page !== null} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Delete page?</ModalHeader>
            <ModalBody>
                <div>
                    Are you sure you want to delete the page named "
                    {props.page && props.page.name}"?
                </div>
                <div>This action cannot be undone.</div>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={clickConfirm}>
                    Delete
                </Button>
                <Button color="secondary" onClick={props.toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
