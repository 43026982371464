import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button
} from "reactstrap";

import "./contentNew.scss";
import { GLOBAL_QUERY, GLOBAL_QUERY_VARIABLES } from "./index";

const slugify = (str: string) => {
    const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;";
    const b = "aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(p, c => b.charAt(a.indexOf(c)))
        .replace(/&/g, "-and-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
};

export default (props: { website: any }) => {
    const [name, setName] = useState("");
    const [pageType, setPageType] = useState({ name: null, typeName: null });
    const [mutationLoading, setMutationLoading] = useState(false);
    const slug = slugify(name);

    const createPage = useMutation(
        gql`
            mutation createWebsitePage($input: CreateWebsitePageInput!) {
                createWebsitePage(input: $input) {
                    id
                    name
                    publishStatus
                    path
                    type {
                        icon
                    }
                    stats {
                        month
                    }
                }
            }
        `,
        {
            variables: {
                input: {
                    name,
                    type: pageType.typeName || pageType.name
                }
            },
            update(proxy, { data: { createWebsitePage } }: any) {
                const data: any = proxy.readQuery({
                    query: GLOBAL_QUERY,
                    variables: GLOBAL_QUERY_VARIABLES
                });

                data.website.pages.push(createWebsitePage);

                proxy.writeQuery({
                    query: GLOBAL_QUERY,
                    data,
                    variables: GLOBAL_QUERY_VARIABLES
                });
            }
        }
    );

    const { data, loading: pageTypesLoading } = useQuery(
        gql`
            query {
                websitePageTypes {
                    name
                    icon
                }
            }
        `
    );

    const pageTypes = (data && data.websitePageTypes) || [];

    const submitForm = (event: any) => {
        event.preventDefault();

        setMutationLoading(true);

        createPage().then((r: any) => {
            window.history.pushState(
                {},
                window.document.title,
                `/app/website/content/${r.data.createWebsitePage.id}`
            );
        });
    };

    if (pageTypesLoading) {
        return null;
    }

    return (
        <Card className="editor-card">
            <CardHeader
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                New Page
            </CardHeader>
            <CardBody>
                <Form onSubmit={submitForm}>
                    <FormGroup row>
                        <Label for="form-name" sm={2}>
                            Name
                        </Label>
                        <Col sm={4}>
                            <Input
                                value={name}
                                onChange={e => setName(e.target.value)}
                                type="text"
                                name="name"
                                id="form-name"
                                placeholder="Page Name"
                                autoComplete={undefined}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="form-slug" sm={2}>
                            URL
                        </Label>
                        <Col sm={4}>
                            <Input
                                disabled
                                type="text"
                                name="slug"
                                id="form-slug"
                                placeholder=""
                                value={slug}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="form-page-type" sm={2}>
                            Page Type
                        </Label>
                        <Col sm={10}>
                            <div className="page-type-list">
                                {pageTypes.map((type: any) => {
                                    return (
                                        <div
                                            className={
                                                "page-type " +
                                                (pageType === type
                                                    ? "active"
                                                    : "")
                                            }
                                            onClick={() => setPageType(type)}
                                            key={type.name}>
                                            <i className="material-icons">
                                                {type.icon}
                                            </i>
                                            <div className="title">
                                                {type.name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="form-create" sm={2} />
                        <Col sm={10}>
                            <Button disabled={mutationLoading}>Create</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
};
