import React from "react";
import { Badge, Table } from "reactstrap";

const StatusBadge = ({
    status
}: {
    status: "Pending" | "Success" | "Failure";
}) => {
    const colorLookup = {
        Pending: "secondary",
        Success: "success",
        Failure: "danger"
    };

    return (
        <Badge color={colorLookup[status]} pill>
            {status}
        </Badge>
    );
};

export default () => {
    const invoices: Array<{
        status: "Pending" | "Success" | "Failure";
        amount: string;
        description: string;
        date: string;
    }> = [
        {
            status: "Pending",
            amount: "$60.00",
            description: "Invoice for March 2019",
            date: "1/3/2019"
        },
        {
            status: "Success",
            amount: "$60.00",
            description: "Invoice for Feburary 2019",
            date: "1/2/2019"
        }
    ];

    return (
        <Table borderless>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map(invoice => (
                    <tr>
                        <td>
                            <StatusBadge status={invoice.status} />
                        </td>
                        <td>{invoice.amount}</td>
                        <td>{invoice.description}</td>
                        <td>{invoice.date}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
