import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Col,
    Input
} from "reactstrap";

import "./designOverview.scss";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";

export default (props: { website: any }) => {
    const o: any = {};
    props.website.themeOptions.map(({ slug, value }: any) => (o[slug] = value));

    const [optionsOverride, setOptionsOverride] = useState(o);

    const defaultOptions: { [key: string]: any } = {};
    props.website.theme.options.forEach(
        (option: any) => (defaultOptions[option.slug] = option.default)
    );

    const options = Object.assign(defaultOptions, optionsOverride);

    const updateWebsiteTheme = useMutation(
        gql`
            mutation updateWebsiteTheme($input: UpdateWebsiteThemeInput!) {
                updateWebsiteTheme(input: $input) {
                    id
                    themeOptions {
                        slug
                        value
                    }
                }
            }
        `,
        {
            variables: {
                input: {
                    websiteID: props.website.id,
                    options: Object.keys(options).map(optionKey => ({
                        slug: optionKey,
                        value: options[optionKey]
                    }))
                }
            }
        }
    );

    const onSubmit = (event: any) => {
        event.preventDefault();

        updateWebsiteTheme();
    };

    return (
        <Card className="design-card">
            <CardHeader>Design</CardHeader>
            <CardBody>
                <Form onSubmit={onSubmit}>
                    <FormGroup row>
                        <Label for="form-name" sm={2}>
                            Theme
                        </Label>
                        <Col sm={4}>
                            <div>
                                <img
                                    style={{ width: 220 }}
                                    src="http://wowslider.com/posts/data/upload/2019/01/bootstrap-theme-3.jpg"
                                    alt="Theme thumbnail"
                                />
                            </div>
                        </Col>
                    </FormGroup>
                    {props.website.theme.options.map((option: any) => {
                        return (
                            <FormGroup row key={option.slug}>
                                <Label
                                    for={`form-option-${option.slug}`}
                                    sm={2}>
                                    {option.name}
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        value={options[option.slug]}
                                        onChange={e =>
                                            setOptionsOverride(
                                                Object.assign(
                                                    {},
                                                    optionsOverride,
                                                    {
                                                        [option.slug]:
                                                            e.target.value
                                                    }
                                                )
                                            )
                                        }
                                        type="text"
                                        name={`form-option-${option.slug}`}
                                        id={`form-option-${option.slug}`}
                                    />
                                </Col>
                            </FormGroup>
                        );
                    })}
                    <FormGroup row>
                        <Label sm={2} />
                        <Col sm={4}>
                            <Button>Save</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
};
