import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

export default (props: { account: any }) => {
    if (!props.account) {
        return null;
    }

    return (
        <Card className="page-card">
            <CardHeader>{props.account.name} Users</CardHeader>
            <CardBody style={{ margin: "1rem" }} />
        </Card>
    );
};
