import React, { useState } from "react";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    Input,
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Label
} from "reactstrap";

export default () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [error, setError] = useState<null | string>(null);
    const [success, setSuccess] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    const changePassword = useMutation(gql`
        mutation ChangePassword($input: ChangePasswordInput!) {
            changePassword(input: $input) {
                success
                error
            }
        }
    `);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== newPassword2) {
            setError(
                "The new passwords you entered do not match. Please try again."
            );

            return;
        }

        if (newPassword.length <= 5) {
            setError("Your password must be greater than 5 characters.");

            return;
        }

        setLoading(true);

        changePassword({
            variables: {
                input: {
                    oldPassword,
                    newPassword
                }
            }
        }).then((r: any) => {
            setLoading(false);
            setError(null);
            setSuccess(null);

            if (r.data.changePassword.success) {
                setSuccess("Your password has successfully been updated.");
                setOldPassword("");
                setNewPassword("");
                setNewPassword2("");
            } else {
                const error: string = r.data.changePassword.error;

                if (error === "InvalidUser") {
                    setError(
                        "There was an error changing your password. Please refresh the page and try again."
                    );
                } else if (error === "InvalidPassword") {
                    setError(
                        "Your old password did not match. Please retry entering your old password."
                    );
                }
            }
        });
    };

    return (
        <Card className="page-card">
            <CardHeader>Account Settings</CardHeader>
            <CardBody style={{ margin: "1rem" }}>
                <Row>
                    <Col md={4}>
                        <h5>Change your password</h5>
                    </Col>
                    <Col>
                        <Form onSubmit={onSubmit}>
                            {error && <Alert color="warning">{error}</Alert>}
                            {success && (
                                <Alert color="success">{success}</Alert>
                            )}
                            <FormGroup>
                                <Label>Current Password</Label>
                                <Input
                                    value={oldPassword}
                                    onChange={e =>
                                        setOldPassword(e.target.value)
                                    }
                                    type="password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>New Password</Label>
                                <Input
                                    value={newPassword}
                                    onChange={e =>
                                        setNewPassword(e.target.value)
                                    }
                                    type="password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Retype New Password</Label>
                                <Input
                                    value={newPassword2}
                                    onChange={e =>
                                        setNewPassword2(e.target.value)
                                    }
                                    type="password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button disabled={loading}>
                                    Change Password
                                </Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                {/* <hr />
                <Row>
                    <Col md={4}>
                        <h5>Deactivate Account</h5>
                    </Col>
                    <Col />
                </Row> */}
            </CardBody>
        </Card>
    );
};
