import React from "react";
import useLocation from "react-use/lib/useLocation";
import { Nav, NavItem, NavLink } from "reactstrap";

import Overview from "./overview";

export default () => {
    const location = useLocation();
    const selectedTab =
        (location.pathname && location.pathname.split("/")[3]) || "";

    const tabs = ["Overview"];

    const onClickTab = (tabName: string) => {
        const url = `/app/account/${tabName.toLowerCase()}`;

        window.history.pushState({}, window.document.title, url);
    };

    const tabComponentMap: { [key: string]: any } = {
        "": () => <Overview />
    };
    const TabComponent = tabComponentMap[selectedTab.toLowerCase()] || null;

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div>
                <Nav vertical className="side-nav">
                    {tabs.map((tab, index) => (
                        <NavItem
                            key={tab}
                            className={
                                selectedTab === tab.toLowerCase() ||
                                (index === 0 && selectedTab === "")
                                    ? "active"
                                    : ""
                            }
                            onClick={() => onClickTab(index === 0 ? "" : tab)}>
                            <NavLink href="#">{tab}</NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            <div style={{ flex: 1 }}>
                <TabComponent />
            </div>
        </div>
    );
};
